import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import Pause from "@mui/icons-material/Pause";
import Play from "@mui/icons-material/PlayArrow";
import React, { PropsWithChildren, useRef, useState } from "react";
import { MemoizedVideo } from "../MemoizedVideo";
import { FHTitle } from "../FHTitle";
import {
  useGetElementDimensions,
  useIsMobile,
  useIsIE,
} from "../../../hooks/hooks";
import { useIsClient } from "../../../hooks/hooks";
import classnames from "classnames";
import { Link, Typography } from "@mui/material";
import { useCookies } from "react-cookie";
import FHButton from "../FHButton";
import { FHNextImage, ImageLoader } from "../FHNextImage";
import whichIcon from "../../assests/UK-HOLIDAY-PARKS.png";

const useStyles = ({
  theme: variant,
  alignBackground,
  bookingWidget,
  compactView: compact,
  tradingTab,
  tradingTabBgColor,
  tradingTabPosition,
  isBookingWidget,
  whichIconInHeroMobile,
}: BannerProps) =>
  makeStyles((theme) => {
    return {
      root: {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        backgroundColor:
          variant === "dark" ? theme.palette.primary.dark : "transparent",
        background: `linear-gradient(to bottom, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 100%) no-repeat ${alignBackground}`,
        backgroundSize: "cover",
        paddingTop: theme.spacing(8),
        [theme.breakpoints.up("md")]: {
          paddingTop: theme.spacing(8),
          minHeight: isBookingWidget ? 518 : "auto",
        },
        [theme.breakpoints.up("lg")]: {
          paddingTop: theme.spacing(10),
        },
        marginTop: `0 !important`,
        marginBottom: tradingTab ? theme.spacing(2) : "0",
      },
      imgGradientOverlay: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundImage:
          "linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))",
      },
      expImgGradientOverlay: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundImage:
          "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))",
      },
      isBannerSticy: {
        paddingTop: `${theme.spacing(18.8)} !important`,
        [theme.breakpoints.up("md")]: {
          paddingTop: `${theme.spacing(13)} !important`,
        },
        [theme.breakpoints.up("lg")]: {
          paddingTop: `${theme.spacing(15)} !important`,
        },
      },
      content: {
        width: tradingTab ? "auto" : "100%",
        position: "relative",
        zIndex: 1,
        minHeight: compact ? 250 : bookingWidget ? 515 : 415,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: compact ? theme.spacing(4, 0) : theme.spacing(8, 0),
        border: "none !important",
        [theme.breakpoints.down("md")]: {
          padding:
            tradingTab || whichIconInHeroMobile
              ? theme.spacing(4, 0)
              : undefined,
          alignItems:
            tradingTab || whichIconInHeroMobile ? "flex-start" : "center",
          justifyContent:
            tradingTab || whichIconInHeroMobile ? "flex-start" : "center",
          minHeight: compact ? 200 : bookingWidget ? "auto" : 350,
        },
        "& > h3": {
          textShadow: "0 0 10px #000000",
          textAlign: "center",
          [theme.breakpoints.down("sm")]: {
            textAlign: tradingTab || whichIconInHeroMobile ? "left" : "center",
          },
        },
        "& .MuiButton-root": {
          color: theme.palette.background.paper,
          borderColor: theme.palette.background.paper,
        },
      },
      bannerTitle: {
        width: "100%",
        ...theme.typography.h1,
        color: "#fff",
        textShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
        textAlign: "center",
        border: "none !important",
        [theme.breakpoints.down("md")]: {
          textAlign: tradingTab || whichIconInHeroMobile ? "left" : "center",
          fontSize:
            tradingTab || whichIconInHeroMobile
              ? theme.typography.h2.fontSize
              : "35px",
          lineHeight: tradingTab || whichIconInHeroMobile ? "35px" : "40px",
        },
      },
      heroVideo: {
        minWidth: "100%",
        height: "100%",
        backgroundSize: "cover",
        transition: "1s opacity",
        objectFit: "fill",
      },
      controls: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        marginTop: theme.spacing(5),
        padding: theme.spacing(1),
        "& .MuiButton-root": {
          color: theme.palette.info.dark,
          border: `2px solid ${theme.palette.info.dark}`,
        },
      },
      playPauseButton: {
        flexShrink: 0,
        border: `1px solid ${theme.palette.background.paper}`,
        padding: theme.spacing(0.5),
      },
      videoContainer: {
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 0,
        overflow: "hidden",
        width: "100%",
        height: "100%",
        minHeight: "100%",
        maxHeight: 645,
        [theme.breakpoints.down("md")]: {
          maxHeight: 720,
        },
        "& video": {
          width: "100%",
        },
      },
      ieVideoStyles: {
        height: "auto",
      },
      controlsCTA: {
        flex: "0 1 320px",
        marginRight: theme.spacing(2),
        padding: theme.spacing(0.75, 1),
        textAlign: "center",
      },
      subtitle: {
        fontSize: "22px",
        lineHeight: "24px",
        paddingTop: theme.spacing(1.5),
        width: "100%",
        textAlign: "center",
        border: "none !important",
        [theme.breakpoints.down("md")]: {
          textAlign: tradingTab || whichIconInHeroMobile ? "left" : "center",
        },
        [theme.breakpoints.down("sm")]: {
          fontSize: "20px",
        },
      },
      titleWrap: {
        position: "relative",
        width: "100%",
        display: "flex",
        alignItems: "center",
        border: "none !important",
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        [theme.breakpoints.down("md")]: {
          padding: theme.spacing(0, 2),
        },
        [theme.breakpoints.down("sm")]: {
          alignItems: whichIconInHeroMobile ? "flex-start" : "center",
        },
        "& h1": {
          border: "none !important",
          backgroundImage: "none !important",
          "&:after": {
            display: "none",
          },
        },
      },
      tradingTabSection: {
        position: "absolute",
        top: "50%",
        right: tradingTabPosition === "right" ? 0 : "auto",
        left: tradingTabPosition === "left" ? 0 : "auto",
        zIndex: 9,
        transform: "translateY(-50%)",
        maxWidth: 160,
        [theme.breakpoints.down("sm")]: {
          maxWidth: "calc(50% - 30px)",
        },
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
      tradingTab: {
        backgroundColor: tradingTabBgColor
          ? tradingTabBgColor
          : theme.palette.warning.light,
        padding: theme.spacing(1.5),
        borderTopLeftRadius: `${tradingTabPosition === "left" ? 0 : "10px"}`,
        borderBottomLeftRadius: `${tradingTabPosition === "left" ? 0 : "10px"}`,
        borderTopRightRadius: `${tradingTabPosition === "left" ? "10px" : 0}`,
        borderBottomRightRadius: `${
          tradingTabPosition === "left" ? "10px" : 0
        }`,
        textAlign: "center",
        boxShadow: "0px 3px 6px rgba(0,0,0,0.3)",
        outline: `0px solid rgba(255,255,255,0.4)`,
        transition: "1s",
        "&:focus, &:hover, &:active": {
          outline: `10px solid rgba(255,255,255,0.4)`,
        },
        "& p": {
          textTransform: "uppercase",
        },
      },
      tradingTabLine1: {
        fontSize: tradingTab?.tabFontSizeLine1
          ? parseInt(tradingTab?.tabFontSizeLine1.toString(), 10)
          : 21,
        lineHeight: tradingTab?.tabFontSizeLine1
          ? `${parseInt(tradingTab?.tabFontSizeLine1.toString(), 10)}px`
          : "21px",
      },
      tradingTabLine2: {
        fontSize: tradingTab?.tabFontSizeLine2
          ? parseInt(tradingTab?.tabFontSizeLine2.toString(), 10)
          : 30,
        lineHeight: tradingTab?.tabFontSizeLine2
          ? `${parseInt(tradingTab?.tabFontSizeLine2.toString(), 10)}px`
          : "30px",
      },
      tradingTabLine3: {
        fontSize: tradingTab?.tabFontSizeLine3
          ? parseInt(tradingTab?.tabFontSizeLine3.toString(), 10)
          : 15,
        lineHeight: tradingTab?.tabFontSizeLine3
          ? `${parseInt(tradingTab?.tabFontSizeLine3.toString(), 10)}px`
          : "15px",
      },
      tradingTabLine4: {
        fontSize: tradingTab?.tabFontSizeLine4
          ? parseInt(tradingTab?.tabFontSizeLine4.toString(), 10)
          : 15,
        lineHeight: tradingTab?.tabFontSizeLine4
          ? `${parseInt(tradingTab?.tabFontSizeLine4.toString(), 10)}px`
          : "15px",
      },
      whichIcon: {
        marginLeft: theme.spacing(1),
        [theme.breakpoints.down(359)]: {
          display: "none",
        },
        [theme.breakpoints.up("md")]: {
          display: "none",
        },
        "& >span": {
          "& >span": {
            "& >img": {
              position: "absolute",
              boxSizing: "border-box",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              padding: 0,
              border: "none",
              margin: "auto",
              display: "block",
              width: 0,
              height: 0,
              minWidth: "100%",
              maxWidth: "100%",
              minHeight: "100%",
              maxHeight: "100%",
            },
          },
        },
      },
    };
  })();

export type BannerProps = {
  imgUrl?: string;
  videoUrl?: string;
  title?: string;
  dontDisplayTitleAsHeading?: boolean;
  subTitle?: string;
  ctaLabel?: string;
  ctaUrl?: string;
  theme?: "light" | "dark";
  alignBackground?: string;
  bookingWidget?: boolean;
  autoplayVideo?: boolean;
  compactView?: boolean;
  tradingTab?: TradingTab;
  tradingTabBgColor?: string;
  tradingTabPosition?: "left" | "right";
  isBannerStickyBar?: boolean;
  imageLoader?: ImageLoader;
  darkImgHeroOverLay?: boolean;
  isBookingWidget?: boolean;
  whichIconInHeroMobile?: boolean;
};

type TradingTab = {
  url: string;
  line1Label?: string;
  line2Label?: string;
  line3Label?: string;
  line4Label?: string;
  tabFontSizeLine1?: number;
  tabFontSizeLine2?: number;
  tabFontSizeLine3?: number;
  tabFontSizeLine4?: number;
};

export const Banner: React.FC<PropsWithChildren<BannerProps>> = ({
  imgUrl,
  videoUrl,
  title,
  dontDisplayTitleAsHeading,
  subTitle,
  ctaLabel,
  ctaUrl,
  theme = "light",
  children,
  alignBackground = "center center",
  bookingWidget = false,
  autoplayVideo,
  compactView = false,
  tradingTab,
  tradingTabBgColor,
  tradingTabPosition,
  isBannerStickyBar,
  imageLoader,
  darkImgHeroOverLay,
  isBookingWidget,
  whichIconInHeroMobile,
}: PropsWithChildren<BannerProps>) => {
  const classes = useStyles({
    theme,
    alignBackground,
    tradingTabBgColor,
    tradingTabPosition,
    bookingWidget,
    compactView,
    tradingTab,
    isBannerStickyBar,
    isBookingWidget,
    whichIconInHeroMobile,
  });
  const isIE = useIsIE();
  const isClient = useIsClient();
  const video = useRef<HTMLVideoElement>(null);
  const [videoPaused, toggleVideoPaused] = useState<boolean>(!autoplayVideo);
  const [cookies] = useCookies(["hideSubscribeSticky"]);
  const isMobile = useIsMobile();
  const componentRef = useRef<HTMLHRElement>(null);
  const { width, height } = useGetElementDimensions(componentRef);

  const tradingTabJsx = (tradingTab: TradingTab) => {
    return (
      <div
        className={classes.tradingTab}
        ref={componentRef}
        style={{
          cursor: (tradingTab.url && "pointer") || "default",
        }}
      >
        {tradingTab.line1Label && (
          <Typography className={classes.tradingTabLine1}>
            <strong>{tradingTab.line1Label}</strong>
          </Typography>
        )}
        {tradingTab.line2Label && (
          <Typography className={classes.tradingTabLine2}>
            <strong>{tradingTab.line2Label}</strong>
          </Typography>
        )}
        {tradingTab.line3Label && (
          <Typography className={classes.tradingTabLine3}>
            <strong>{tradingTab.line3Label}</strong>
          </Typography>
        )}
        {tradingTab.line4Label && (
          <Typography className={classes.tradingTabLine4}>
            <strong>{tradingTab.line4Label}</strong>
          </Typography>
        )}
      </div>
    );
  };

  const tradingTabStyles = () => {
    if (isClient) {
      return isMobile ? 20 : width + 15;
    }
  };

  return (
    <header
      className={`${classes.root} ${
        !cookies.hideSubscribeSticky && isBannerStickyBar
          ? classes.isBannerSticy
          : ""
      }`}
      data-testid="bannerRoot"
    >
      {imgUrl && (
        <>
          <FHNextImage
            src={imgUrl}
            alt={title}
            layout="fill"
            objectFit="cover"
            quality={50}
            priority
            isBannerImg={true}
          />
          <div
            className={
              darkImgHeroOverLay
                ? classes.expImgGradientOverlay
                : classes.imgGradientOverlay
            }
          />
        </>
      )}
      {videoUrl && (
        <div className={classes.videoContainer}>
          <MemoizedVideo
            {...{
              src: videoUrl + "#t=0.001",
              className: classnames(classes.heroVideo, {
                [classes.ieVideoStyles]: isIE,
              }),
              innerRef: video,
              autoPlay: autoplayVideo,
            }}
          />
        </div>
      )}
      {(title || subTitle) && (
        <div className={classes.content}>
          {title && (
            <div
              className={classes.titleWrap}
              style={{
                paddingLeft: tradingTabStyles(),
                paddingRight: tradingTabStyles(),
                minHeight: isMobile && tradingTab ? height : undefined,
              }}
            >
              <FHTitle
                text={title}
                variant={dontDisplayTitleAsHeading ? "body1" : "h1"}
                className={classes.bannerTitle}
              />
              {tradingTab && (
                <div className={classes.tradingTabSection}>
                  {tradingTab.url ? (
                    <Link href={tradingTab.url} underline="none">
                      {tradingTabJsx(tradingTab)}
                    </Link>
                  ) : (
                    tradingTabJsx(tradingTab)
                  )}
                </div>
              )}
              {whichIconInHeroMobile && (
                <div className={classes.whichIcon}>
                  <FHNextImage
                    src={whichIcon}
                    alt="Which? Recommended Provider"
                    isBannerImg={true}
                    width={176}
                    height={140}
                  />
                </div>
              )}
            </div>
          )}
          {subTitle && (
            <div
              className={classes.titleWrap}
              style={{
                paddingLeft: tradingTabStyles(),
                paddingRight: tradingTabStyles(),
              }}
            >
              <FHTitle
                text={subTitle}
                variant="body1"
                colour="white"
                className={classes.subtitle}
              />
            </div>
          )}
          {((ctaLabel && ctaUrl) || videoUrl) && !compactView && (
            <div className={classes.controls}>
              {ctaLabel && (
                <FHButton
                  data-testid="bannerCta"
                  href={ctaUrl}
                  className={classes.controlsCTA}
                  fhStyle={"tertiary"}
                  fhSize={"md"}
                >
                  {ctaLabel}
                </FHButton>
              )}
              {videoUrl && (
                <IconButton
                  className={classes.playPauseButton}
                  onClick={() => {
                    if (videoPaused) {
                      video.current?.play();
                      toggleVideoPaused(false);
                    } else {
                      video.current?.pause();
                      toggleVideoPaused(true);
                    }
                  }}
                  data-testid="bannerVideoAction"
                >
                  {videoPaused ? <Play /> : <Pause />}
                </IconButton>
              )}
            </div>
          )}
        </div>
      )}
      {children}
    </header>
  );
};
